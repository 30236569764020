import React from 'react';
import { Box } from '@mui/material';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Virtual } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/virtual";

const maxWidth = 560;

interface Image {
    src: string;
    alt: string;
}

interface CarouselProps {
    images: Image[];
}

function Carousel({ images }: CarouselProps): JSX.Element {
    return (
        <Box sx={(theme) => ({
            maxWidth: maxWidth, [theme.breakpoints.down('sm')]: {
                maxWidth: 360,
            },
        })}>
            <Swiper
                centeredSlides
                virtual
                modules={[Autoplay, Pagination, Virtual]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                pagination={{ clickable: true }}>
                {images.map((image, index) => (
                    <SwiperSlide key={image.alt + index} virtualIndex={index}>
                        <Box
                            component='img'
                            src={image.src}
                            alt={image.alt}
                            sx={(theme) => ({
                                display: 'block',
                                maxWidth: maxWidth,
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: 360,
                                },
                                overflow: 'hidden',
                                'object-fit': 'cover',
                                width: '100%',
                            })}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    )
}

export default Carousel