import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Carousel from './Carousel';

function KissFollower(): JSX.Element {
    return (
        <Grid container spacing={2} sx={{ marginBottom: (theme) => theme.spacing(2) }}>
            <Grid item sm={12} sx={(theme) => ({ [theme.breakpoints.down('sm')]: { flexGrow: 1 } })}>
                <Box sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing(1),
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                    },
                })}>
                    <Typography sx={(theme) => ({ [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(1) } })} variant='h4'>The KISS 12 GA SFLR REM</Typography>
                    <Button variant='contained' href='mailto:jnldynamics@gmail.com?subject=KISS 12GA SFLR REM Inquiry' target='_blank'>Email Us</Button>
                </Box>

                <Divider />
            </Grid>

            <Grid item sm={6}>
                <Typography sx={{ padding: (theme) => theme.spacing(2, 0) }}>The KISS 12 GA SFLR REM is a replacement for the stock shotgun follower.</Typography>
                <Typography variant='h6'>Features:</Typography>
                <ul>
                    <li>Inside bearing surface reduced by 50%.</li>
                    <li>Grooves give fowling a place to move rather than pushing it back and forth (prevents binding).</li>
                    <li>Highly visible Canary Yellow (allows immediate confirmation that the tube is clear).</li>
                    <li>Constructed of a high strength polymer.</li>
                </ul>
            </Grid>

            <Grid item sm={6}>
                <Carousel images={[
                    { src: "https://jldynamics-media.s3.amazonaws.com/images/IMG_2904.JPG", alt: "The KISS 12 GA SFLR shotgun follower" },
                    { src: "https://jldynamics-media.s3.amazonaws.com/images/IMG_0820.JPG", alt: "Info card" },
                    { src: "https://jldynamics-media.s3.amazonaws.com/images/IMG_0813.JPG", alt: "Large image" },
                    { src: "https://jldynamics-media.s3.amazonaws.com/images/IMG_0816.JPG", alt: "Side profile" },
                ]} />
            </Grid>
        </Grid>
    )
}

export default KissFollower