import { AppBar, Box, Container, Divider, Toolbar, Typography } from '@mui/material';
import React from 'react';
import JohnnyClip from './JohnnyClip';
import KissFollower from './KissFollower';

function App() {
  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h3'>J &amp; L Dynamics, LLC</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ margin: (theme) => theme.spacing(2, 'auto') }}>
        <JohnnyClip />

        <KissFollower />

        <Divider />

        <Box sx={{ margin: (theme) => theme.spacing(2, 0), display: 'flex', height: 650, '& iframe': { display: 'flex', flexGrow: 1 } }}>
          <iframe src="https://www.youtube.com/embed/oAdpr9LJzIE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </Box>

        <Divider />

        <Box sx={{ marginTop: (theme) => theme.spacing(2), display: 'flex', height: 650, '& iframe': { display: 'flex', flexGrow: 1 } }}>
          <iframe src="https://www.youtube.com/embed/1_eMHiAdtQQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </Box>
      </Container>
    </>
  );
}

export default App;
