import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Carousel from './Carousel';

const couponStartDate = new Date('July 4, 2024 00:00:00');
const couponEndDate = new Date('July 7, 2024 23:59:59');
const today = new Date();
const isCouponActive = today >= couponStartDate && today <= couponEndDate;

function JohnnyClip(): JSX.Element {
    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={2} sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                <Grid item sm={12} sx={(theme) => ({ [theme.breakpoints.down('sm')]: { flexGrow: 1 } })}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing(1),
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                        },
                    })}>
                        <Typography sx={(theme) => ({ [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(1) } })} variant='h4'>Johnny Clip 2x4</Typography>

                        <Box>
                            {isCouponActive && (<Button sx={(theme) => ({ marginRight: theme.spacing(1) })} variant='text' onClick={handleOpen}>Get Coupon</Button>)}
                            <Button variant='contained' href='https://www.amazon.com/dp/B01HOPBR8C' target='_blank'>Buy on Amazon</Button>
                        </Box>
                    </Box>

                    <Divider />
                </Grid>

                <Grid item sm={6}>
                    <Typography sx={{ padding: (theme) => theme.spacing(2, 0) }}>The Johnny Clip 2x4 Shell Caddy is a shell holder for 2.75”-3” 12 gauge shotgun shells. It allows users to 'load-two' or 'load-four' for their tube fed shotgun. As you can see in the video, speed loading can be accomplished easily and more efficiently with practice. These methods are easy to learn and are very fast. The Johnny Clip 2x4 is ideal for any rapid shotgun shooting sport. It allows shooters to minimize loading time and get back to shooting. The Johnny Clip 2x4 is ideal for tactical applications, 3 Gun competition and bird hunting.</Typography>
                    <Typography variant='h6'>Features:</Typography>
                    <ul>
                        <li>8 Shell Capacity.</li>
                        <li>Tight spacing between shells arranged to allow 'load-two' or 'load-four' methods.</li>
                        <li>Four mounting solutions: 2” Belt, Molle, BladeTech Tek-Lok, and Safari Land ELS.</li>
                        <li>Simple, light, and rugged injection molded polymer.</li>
                    </ul>
                </Grid>

                <Grid item sm={6}>
                    <Carousel images={[
                        { src: "https://jldynamics-media.s3.amazonaws.com/images/johnny-clip/Slide1.JPG", alt: "Honoring the life and acheivements of SGT Johnny D. McCulley" },
                        { src: "https://jldynamics-media.s3.amazonaws.com/images/johnny-clip/Slide2.JPG", alt: "Four mounting methods" },
                        { src: "https://jldynamics-media.s3.amazonaws.com/images/johnny-clip/Slide4.JPG", alt: "How to load four with the Johnny Clip 2x4" },
                        { src: "https://jldynamics-media.s3.amazonaws.com/images/johnny-clip/Slide5.JPG", alt: "Life sketch of SGT Johnny D. McCulley" },
                    ]} />
                </Grid>
            </Grid>

            {isCouponActive && (
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="johnny-coupon-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="johnny-coupon-title">🎉Celebrate Johnny's 57th Birthday🎂</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Help us celebrate STG Johnny McCulley's 57th birthday!
                            Apply coupon code <Typography variant='caption'>JOHNNY57</Typography> at checkout to receive <strong>37% off</strong> your purchase.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' href='https://www.amazon.com/dp/B01HOPBR8C' target='_blank'>Buy on Amazon</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>

    )
}

export default JohnnyClip